"use client";

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Item } from "@/types/common";
import { SelectOptionsDataType } from "@/constants/common";
import { useSelectsOptionsFetchers } from "@/hooks/useSelectsOptionsFetchers";

export interface MyReportingProviderProps {
  children: ReactNode;
  bannersAreLoaded: boolean;
}

type MyReportingType = {
  activityTypes: Item[];
  loading: boolean;
  bannersAreLoaded: boolean;
};

const MyReportingContext = createContext<MyReportingType>({
  activityTypes: [],
  loading: false,
  bannersAreLoaded: false,
});

export const MyReportingProvider: React.FC<MyReportingProviderProps> = ({ children, bannersAreLoaded }) => {
  const { fetchSelectOptions } = useSelectsOptionsFetchers();

  const [activityTypes, setActivityTypes] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchSelectOptions(SelectOptionsDataType.activityTypes, setLoading);

      setActivityTypes(data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <MyReportingContext.Provider value={{ activityTypes, loading, bannersAreLoaded }}>
      {children}
    </MyReportingContext.Provider>
  );
};

export const useMyReportingTabs = () => useContext(MyReportingContext);
