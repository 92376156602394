import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StyledHeader } from "@/src/components/AppHeader/ Styled";
import { Grid, Space } from "antd/lib";
import Image from "next/image";
import { MY_PROFILE_BASE_PATH, personalInfoUrl } from "@/utils/appUrls";
import Link from "next/link";
import { useAuth } from "@/context/AuthContext";
import PageTitle from "@/src/components/Common/PageTitle";
import LogoWithBurger from "@/src/components/Common/LogoWithBurger";
import { usePath } from "@/hooks/usePath";

const { useBreakpoint } = Grid;

interface AppHeaderProps {
  loading: boolean;
}
const AppHeader: React.FC<AppHeaderProps> = ({ loading }) => {
  const { user } = useAuth0();
  const { avatar } = useAuth();
  const screens = useBreakpoint();
  const pathname = usePath();

  return (
    <StyledHeader>
      {screens.lg ? <PageTitle /> : <LogoWithBurger />}
      <Space style={{ lineHeight: "normal" }}>
        {user && avatar && (
          <Link
            href={personalInfoUrl()}
            style={{ cursor: pathname.startsWith(MY_PROFILE_BASE_PATH) ? "default" : "pointer" }}
          >
            <Image
              src={avatar || ""}
              alt="Profile"
              width={40}
              height={40}
              style={{
                borderRadius: "50%",
              }}
            />
          </Link>
        )}
      </Space>
    </StyledHeader>
  );
};

export default AppHeader;
