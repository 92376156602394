"use client";

import React from "react";
import Thi from "@/public/icons/Thi";
import Thi2 from "@/public/icons/Thi (2)";
import { Flex, Grid, Space, Typography } from "antd/lib";
import { Facebook, Inst } from "@/public/icons";
import Link from "next/link";
import styles from "./AppFooter.module.scss";
import { useTranslation } from "react-i18next";
import { cookieConsentUrl } from "@/utils/appUrls";

const { Text, Paragraph } = Typography;
const { Link: AntLink } = Typography;
const { useBreakpoint } = Grid;

const AppFooter: React.FC = (): JSX.Element => {
  const screens = useBreakpoint();
  const { t } = useTranslation();

  return (
    <Flex vertical justify="center" gap={28} className={styles.footerContainer}>
      <Flex gap={40} justify="space-between" align={screens.sm ? "center" : "flex-start"} vertical={!screens.sm}>
        <Space className={styles.frame2}>
          <a href="https://traumahealinginstitute.org/" target="_blank">
            <Thi className={styles.thiLogoFull} color="#1CAB9F" />
          </a>
          <Space className={styles.frame3}>
            <Flex gap={16}>
              <Link href="https://www.facebook.com/traumhealinginstitute" passHref legacyBehavior>
                <AntLink target="_blank" rel="noopener noreferrer">
                  <Facebook className={styles.linkWrapper} />
                </AntLink>
              </Link>
              <Link href="https://www.instagram.com/traumahealinginstitute" passHref legacyBehavior>
                <AntLink target="_blank" rel="noopener noreferrer">
                  <Inst className={styles.linkWrapper} />
                </AntLink>
              </Link>
            </Flex>
            <Text className={styles.textWrapper2}>{t("footer.copyright")}</Text>
            <Space className={styles.frame6}>
              <Link href={cookieConsentUrl()} passHref legacyBehavior>
                <AntLink className={styles.textWrapper2}>{t("footer.cookies")}</AntLink>
              </Link>
              <Link href="https://www.americanbible.org/about/legal/" passHref legacyBehavior>
                <AntLink className={styles.textWrapper2} target="_blank">
                  {t("footer.privacyPolicy")}
                </AntLink>
              </Link>
            </Space>
          </Space>
        </Space>
        <Space className={styles.frame4}>
          <Text className={styles.textWrapper}>Powered By</Text>
          <Thi2 className="abs-logo-horizon" color="#1CAB9F" />
          <Paragraph className={styles.pContainer}>{t("footer.address")}</Paragraph>
        </Space>
      </Flex>
    </Flex>
  );
};

export default AppFooter;
