import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontFamily: 'inherit',
    fontSize: 14,
    colorPrimary: '#006D67',
  },
  components: {
    Typography: {
      fontSizeHeading1: 24,
      fontSizeHeading2: 24,
      fontSizeHeading3: 22,
    },
    Menu: {
      itemSelectedColor: 'rgb(0, 153, 143)',
      itemSelectedBg: 'rgb(223, 247, 243)',
      subMenuItemBg: 'rgb(255, 255, 255)',
    },
    Collapse: {
      headerPadding: '12px 0px',
      contentPadding: '16px 0',
    },
  },
};

export default theme;
