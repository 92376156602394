import React from "react";
import { ChevronRightStroke } from "@/public/icons/ChevronRightStroke";
import Image from "next/image";
import { squidexAssetUrl } from "@/utils/appUrls";
import Link from "next/link";
import styles from "@/src/components/Common/Banners/Banners.module.scss";

interface GenericBannerProps {
  imageUrl: string;
  text: string;
  buttonText: string;
  buttonUrl: string;
}

const GenericBanner: React.FC<GenericBannerProps> = ({ imageUrl, text, buttonText, buttonUrl }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <p className={styles.text}>{text}</p>
        <div className={styles.linkWrapper}>
          <Link href={buttonUrl} className={styles.linkBtn}>
            <span>{buttonText}</span>
            <ChevronRightStroke />
          </Link>
        </div>
      </div>
      <Image className={styles.image} alt="" src={squidexAssetUrl(imageUrl)} width={271} height={180} />
    </div>
  );
};

export default GenericBanner;
