"use client";

import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Breakpoint, Button, Drawer, Flex, Grid, Layout, Menu, MenuProps, Tour } from "antd/lib";
import { useRouter } from "next/navigation";
import Link from "next/link";
import {
  adultTrainingUrl,
  DASHBOARD_BASE_PATH,
  dashboardUrl,
  favoritesProgramsUrl,
  findUsersDashboardUrl,
  materialsLibraryUrl,
  MY_EVENTS_BASE_PATH,
  MY_PROFILE_BASE_PATH,
  MY_PROGRAMS_BASE_PATH,
  MY_REPORTING_BASE_PATH,
  myHealingReportingUrl,
  ongoingMyEventsUrl,
  personalInfoUrl,
  PUBLIC_EVENTS_BASE_PATH,
  publicEventsUrl,
  TRAINING_BASE_PATH,
} from "@/utils/appUrls";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/context/AuthContext";
import XStroke from "../../../public/icons/XStroke";
import { useSidebarTutorial } from "@/context/SidebarTutorialContext";
import { SidebarTutorialSlugs } from "@/constants/tour";
import { Envs } from "@/constants/common";
import { useUserData } from "@/hooks/useUserData";
import { useMenu } from "@/context/MenuContext";
import { usePath } from "@/hooks/usePath";
import LogoWithBurger from "@/src/components/Common/LogoWithBurger";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const Sidebar: React.FC = () => {
  const { loginWithRedirect, logout, isLoading, user } = useAuth0();
  const { checkUserIsJustAContact } = useUserData();
  const { onboardingIsCompleted, userIsAdmin } = useAuth();
  const router = useRouter();
  const pathname = usePath();
  const { t } = useTranslation();
  const {
    sidebarTutorialSlug,
    sidebarTourIsOpen,
    tourWasShown,
    steps,
    dashboardRef,
    myProgramsRef,
    materialsLibraryRef,
    myEventsRef,
    myReportingRef,
    myProfileRef,
    publicEventsRef,
    openTutorialIconRef,
    setTourWasShown,
    setSidebarTutorialSlug,
    onFinish,
  } = useSidebarTutorial();
  const { collapsed, setCollapsed } = useMenu();
  const screens = useBreakpoint();
  const loginLogoutText = user ? t("common.sidebar.logout") : t("common.sidebar.logIn");

  const [tourIsOpen, setTourIsOpen] = useState<boolean>(sidebarTourIsOpen);
  const [openKeys, setOpenKeys] = useState<string[]>([dashboardUrl()]);
  const [logoIsRendered, setLogoIsRendered] = useState(false);

  const defaultSelectedKeys: string[] = [];
  if (pathname.startsWith(MY_EVENTS_BASE_PATH)) defaultSelectedKeys.push(ongoingMyEventsUrl());
  else if (pathname.startsWith(MY_PROGRAMS_BASE_PATH)) defaultSelectedKeys.push(favoritesProgramsUrl());
  else if (pathname.startsWith(PUBLIC_EVENTS_BASE_PATH)) defaultSelectedKeys.push(publicEventsUrl());
  else if (pathname.startsWith(MY_REPORTING_BASE_PATH)) defaultSelectedKeys.push(myHealingReportingUrl(0));
  else if (pathname.startsWith(MY_PROFILE_BASE_PATH)) defaultSelectedKeys.push(personalInfoUrl());
  else if (pathname.startsWith(TRAINING_BASE_PATH)) defaultSelectedKeys.push(adultTrainingUrl());
  else defaultSelectedKeys.push(pathname);

  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);

  useEffect(() => {
    setSelectedKeys(defaultSelectedKeys);
  }, [pathname]);

  useEffect(() => {
    if (tourIsOpen !== sidebarTourIsOpen) setTourIsOpen(sidebarTourIsOpen);
  }, [sidebarTourIsOpen]);

  useEffect(() => {
    if (tourIsOpen && !openKeys.length && pathname.startsWith(DASHBOARD_BASE_PATH)) setOpenKeys([dashboardUrl()]);
  }, [tourIsOpen]);

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleAuthentication = (): void => {
    user ? handleLogout() : handleLogin();
  };

  const handleNavigation = (route: string) => {
    router.push(route);
  };

  const handleClicked = (key: string) => {
    if (!screens.lg) setCollapsed(true);

    setSelectedKeys([key]);

    key === "login/logout" ? handleAuthentication() : handleNavigation(key);
  };

  const existingClass = styles.sidebar;

  const collapsedClass = collapsed ? styles.sidebar_collapsed : styles.sidebar_expanded;

  const combinedClass = `${existingClass} ${collapsedClass}`;

  const defaultLabelColor = collapsed ? "#fff" : "black";
  const selectedLabelColor = collapsed ? "white" : "#00998F";

  const menuItems: MenuProps["items"] = [];
  const dashboardItems: MenuProps["items"] = [];

  if (user) {
    if (onboardingIsCompleted) {
      menuItems.push({
        key: dashboardUrl(),
        className: pathname === dashboardUrl() ? "ant-menu-item-selected" : "",
        icon: (
          <Link href={dashboardUrl()} ref={dashboardRef}>
            <i
              className={`icon-grid_stroke ${styles.gridIcon}`}
              style={{
                color: pathname === dashboardUrl() ? "#00998F" : "black",
              }}
            />
          </Link>
        ),
        label: (
          <Link
            href={dashboardUrl()}
            style={{
              fontWeight: "600",
              color: pathname === dashboardUrl() ? "#00998F" : "black",
              backgroundColor: "inherit",
              textDecoration: "inherit",
              touchAction: "inherit",
            }}
          >
            {t("common.sidebar.dashboard")}
          </Link>
        ),
        children: [],
      });

      if (userIsAdmin) {
        dashboardItems.push({
          key: findUsersDashboardUrl(),
          label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.findUsersDashboard")}</span>,
          onClick: (e) => handleNavigation(e.key),
          style: { color: "black" },
        });
      }

      if (!checkUserIsJustAContact(user)) {
        dashboardItems.push(
          {
            key: ongoingMyEventsUrl(),
            icon: <i ref={myEventsRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myEvents")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
          {
            key: favoritesProgramsUrl(),
            icon: <i ref={myProgramsRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myPrograms")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
          {
            key: myHealingReportingUrl(0),
            icon: <i ref={myReportingRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myReporting")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
          {
            key: personalInfoUrl(),
            icon: <i ref={myProfileRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myProfile")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
          // {
          //   key: myImpactUrl(),
          //   label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myImpact")}</span>,
          //   onClick: (e) => handleNavigation(e.key),
          //   style: { color: "black" },
          // },
        );
      } else {
        dashboardItems.push(
          {
            key: favoritesProgramsUrl(),
            icon: <i ref={myProgramsRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myFavorites")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
          {
            key: personalInfoUrl(),
            icon: <i ref={myProfileRef} className="hiddenIcon"></i>,
            label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myProfile")}</span>,
            onClick: (e) => handleNavigation(e.key),
            style: { color: "black" },
          },
        );
      }

      menuItems.push(
        {
          key: materialsLibraryUrl(),
          icon: <i className={`icon-book_stroke ${styles.bookIcon}`} ref={materialsLibraryRef}></i>,
          label: (
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {t("common.sidebar.materialsLibrary")}
            </span>
          ),
          onClick: (e) => handleNavigation(e.key),
        },
        {
          key: publicEventsUrl(),
          icon: <i className={`icon-calendar_stroke ${styles.calendarIcon}`} ref={publicEventsRef}></i>,
          label: (
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {t("common.sidebar.events")}
            </span>
          ),
          onClick: (e) => handleNavigation(e.key),
        },
        {
          key: adultTrainingUrl(),
          icon: <i className={`icon-trending-up_stroke ${styles.trendingUpIcon}`}></i>,
          label: (
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {t("common.sidebar.training")}
            </span>
          ),
          onClick: (e) => handleNavigation(e.key),
        },
      );
    } else {
      menuItems.push({
        key: personalInfoUrl(),
        icon: <i className={`icon-user_stroke ${styles.userIcon}`}></i>,
        label: <span style={{ fontWeight: "400" }}>{t("common.sidebar.myProfile")}</span>,
        onClick: (e) => handleNavigation(e.key),
        style: { color: "black" },
      });
    }

    menuItems.push({
      key: "login/logout",
      icon: <i className={`icon-logout_stroke ${styles.logoutIcon}`}></i>,
      label: <span style={{ fontWeight: "600" }}>{loginLogoutText}</span>,
      onClick: () => handleAuthentication(),
    });
  } else {
    menuItems.push(
      {
        key: publicEventsUrl(),
        icon: <i className={`icon-calendar_stroke ${styles.calendarIcon}`} ref={publicEventsRef}></i>,
        label: (
          <span
            style={{
              alignSelf: "flex-start",
              fontWeight: "600",
            }}
          >
            {t("common.sidebar.events")}
          </span>
        ),
        onClick: (e) => handleNavigation(e.key),
      },
      {
        key: "login/logout",
        icon: <i className={`icon-log-in_stroke ${styles.loginIcon}`}></i>,
        label: <span style={{ fontWeight: "600" }}>{loginLogoutText}</span>,
        onClick: () => handleAuthentication(),
      },
    );
  }

  const mappedMenuItems = menuItems.map((item) =>
    item?.key === dashboardUrl() ? { ...(item || {}), children: dashboardItems } : item,
  );

  return (
    <MenuWrapper collapsed={collapsed} screens={screens} setCollapsed={setCollapsed}>
      <Flex vertical gap={24} justify="space-between" style={{ height: "100%" }}>
        <Flex vertical>
          {!!screens.lg && <LogoWithBurger setLogoIsRendered={setLogoIsRendered} />}
          {!isLoading && (
            <Menu
              items={mappedMenuItems}
              mode="inline"
              inlineIndent={33}
              theme="light"
              defaultOpenKeys={[dashboardUrl()]}
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={(openKeys: string[]) => setOpenKeys(openKeys)}
              onSelect={(e) => handleClicked(e.key)}
              style={{ borderInlineEnd: "none" }}
            />
          )}
        </Flex>
        <Flex vertical gap={16} className="sidebar-footer">
          {user &&
            onboardingIsCompleted &&
            tourWasShown.main &&
            screens.xl &&
            !pathname.startsWith(TRAINING_BASE_PATH) && (
              <Button
                shape="circle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={<i className={`icon-student_stroke ${styles.studentIcon}`} />}
                type="text"
                ref={openTutorialIconRef}
                onClick={() => {
                  setTourWasShown({ ...tourWasShown, main: false });
                }}
              />
            )}

          <p>
            {process.env.APP_ENV !== Envs.production && (
              <>{process.env.APP_ENV === Envs.development ? "dev" : process.env.APP_ENV} | </>
            )}
            v {process.env.APP_VERSION}
          </p>
        </Flex>
      </Flex>

      {!!sidebarTutorialSlug &&
        (!pathname.startsWith(DASHBOARD_BASE_PATH) || pathname === dashboardUrl() || !!openKeys.length) &&
        logoIsRendered && (
          <Tour
            placement="right"
            open={tourIsOpen}
            steps={steps}
            indicatorsRender={() => <></>}
            closeIcon={
              <XStroke
                color="#000"
                onClick={() => {
                  setSidebarTutorialSlug(undefined);

                  if (sidebarTutorialSlug === SidebarTutorialSlugs.openTutorialIcon) {
                    setTourWasShown({ ...tourWasShown, openTutorialIcon: true });
                  } else {
                    if (!tourWasShown.openTutorialIcon) setSidebarTutorialSlug(SidebarTutorialSlugs.openTutorialIcon);
                    setTourWasShown({ ...tourWasShown, main: true });
                  }
                }}
              />
            }
            onFinish={onFinish}
          />
        )}
    </MenuWrapper>
  );
};
export default Sidebar;

interface MenuWrapperProps {
  screens: Partial<Record<Breakpoint, boolean>>;
  collapsed?: boolean;
  children: ReactNode;
  setCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({ screens, collapsed, children, setCollapsed }) => {
  return screens.lg ? (
    <Sider
      collapsed={collapsed}
      trigger={null}
      theme={"light"}
      collapsible
      width={215}
      collapsedWidth={111}
      className={styles.sidebar}
    >
      {children}
    </Sider>
  ) : (
    <Drawer
      open={collapsed === false}
      placement="left"
      styles={{ header: { display: "none" }, body: { padding: "32px 0" } }}
      onClose={() => setCollapsed(true)}
      className={styles.sidebar}
    >
      {children}
    </Drawer>
  );
};
