"use client";

import { Flex, Grid, Typography } from "antd/lib";
import {
  cookieConsentUrl,
  createOrganizationUrl,
  dashboardUrl,
  findUsersDashboardUrl,
  materialsLibraryUrl,
  MY_EVENTS_BASE_PATH,
  MY_PROFILE_BASE_PATH,
  MY_PROGRAMS_BASE_PATH,
  MY_REPORTING_BASE_PATH,
  myImpactUrl,
  participantFormUrl,
  PUBLIC_EVENTS_BASE_PATH,
  TRAINING_BASE_PATH,
} from "@/utils/appUrls";
import React from "react";
import { useUserData } from "@/hooks/useUserData";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/context/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import { usePath } from "@/hooks/usePath";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

interface WelcomeMessages {
  [key: string]: string;
}

const PageTitle: React.FC = () => {
  const { user } = useAuth0();
  const { firstName } = useAuth();
  const { checkUserIsJustAContact } = useUserData();
  const pathname = usePath();
  const { t } = useTranslation();
  const today = new Date();
  const date = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const screens = useBreakpoint();

  const pageTitlesTexts: WelcomeMessages = {
    "/": "",
    [myImpactUrl()]: t("myImpactPage.header.title"),
    [materialsLibraryUrl()]: t("materialsLibraryPage.header.title"),
    [dashboardUrl()]: firstName ? t("dashboardPage.header.title.welcome", { firstName: firstName }) : "",
    [findUsersDashboardUrl()]: t("findUsersDashboardPage.header.title"),
    [createOrganizationUrl()]: t("createOrganizationPage.header.title"),
    [participantFormUrl()]: t("participantFormPage.header.title"),
    [cookieConsentUrl()]: t("cookieConsent.pageTitle"),
  };

  let pageTitlesText;
  if (pathname.startsWith(PUBLIC_EVENTS_BASE_PATH)) pageTitlesText = t("eventsPage.header.publicEventsTitle");
  else if (pathname.startsWith(MY_EVENTS_BASE_PATH)) pageTitlesText = t("eventsPage.header.myEventsTitle");
  else if (pathname.startsWith(MY_PROGRAMS_BASE_PATH)) {
    pageTitlesText = user
      ? checkUserIsJustAContact(user)
        ? t("myProgramsPage.header.title.contactUsers")
        : t("myProgramsPage.header.title.moreThanContactUsers")
      : "";
  } else if (pathname.startsWith(MY_REPORTING_BASE_PATH)) pageTitlesText = t("myReportingPage.header.title");
  else if (pathname.startsWith(MY_PROFILE_BASE_PATH)) pageTitlesText = t("myProfilePage.header.title");
  else if (pathname.startsWith(TRAINING_BASE_PATH)) pageTitlesText = t("trainingPage.header.title");
  else
    pageTitlesText =
      pageTitlesTexts[pathname] || pageTitlesTexts[pathname] === ""
        ? pageTitlesTexts[pathname]
        : t("notFoundPage.header.title");

  return (
    <Flex vertical style={{ marginBottom: screens.lg ? 0 : "24px" }}>
      <Text
        style={{
          fontSize: "24px",
          fontWeight: 600,
          margin: 0,
          padding: 0,
          lineHeight: "normal",
        }}
      >
        <Flex justify="start" align="center" gap={16}>
          <Title level={1}>{pageTitlesText}</Title>
        </Flex>
      </Text>
      {pathname === dashboardUrl() && (
        <Text
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: 400,
            margin: 0,
            padding: 0,
            lineHeight: "normal",
          }}
        >
          {t("dashboardPage.header.title.date", { date })}
        </Text>
      )}
    </Flex>
  );
};

export default PageTitle;
