import {
  apiActivityLanguages,
  apiActivityTypes,
  apiAges,
  apiAudiences,
  apiContacts,
  apiCountries,
  apiDenominations,
  apiEventPeople,
  apiFacilitatorMaterials,
  apiInternationalOrganizations,
  apiLanguages,
  apiLessons,
  apiLessonSelectionModes,
  apiMaterials,
  apiMyMaterials,
  apiOrganizations,
  apiOrganizationsExpertiseAreas,
  apiOrganizationsTypes,
  apiPeople,
  apiProfileListsData,
} from '@/utils/apiUrls';
import { LocalStorageKeys, SearchParams, SelectOptionsDataType } from '@/constants/common';
import {
  LessonModeOption,
  LessonsResponse,
  ProfileListsDataSelectOptionsResponse,
  SelectOptionsResponse,
} from '@/types/selectOptions';
import { Dispatch, SetStateAction } from 'react';
import { EventsActivityTypes } from '@/constants/events';
import { Item } from '@/types/common';
import { useCommonFetchers } from '@/hooks/useCommonFetchers';

export const useSelectsOptionsFetchers = () => {
  const { fetcher } = useCommonFetchers();

  const fetchSelectOptions = async (
    dataType: SelectOptionsDataType,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    searchString = '',
    eventId?: string,
    userId?: string,
  ): Promise<SelectOptionsResponse> => {
    let apiPath;

    switch (dataType) {
      case SelectOptionsDataType.languages:
        apiPath = apiLanguages(searchString);
        break;
      case SelectOptionsDataType.activityLanguages:
        apiPath = apiActivityLanguages();
        break;
      case SelectOptionsDataType.organizations:
        apiPath = apiOrganizations('', { [SearchParams.filterSearch]: searchString });
        break;
      case SelectOptionsDataType.internationalOrganizations:
        apiPath = apiInternationalOrganizations(searchString);
        break;
      case SelectOptionsDataType.people:
        apiPath = eventId ? apiEventPeople(eventId, searchString) : apiPeople(searchString);
        break;
      case SelectOptionsDataType.materials:
        apiPath = userId ? apiFacilitatorMaterials(userId) : apiMaterials();
        break;
      case SelectOptionsDataType.myMaterials:
        apiPath = apiMyMaterials();
        break;
      case SelectOptionsDataType.audiences:
        apiPath = apiAudiences();
        break;
      case SelectOptionsDataType.ages:
        apiPath = apiAges();
        break;
      case SelectOptionsDataType.activityTypes:
        apiPath = apiActivityTypes();
        break;
      case SelectOptionsDataType.countries:
        apiPath = apiCountries();
        break;
      case SelectOptionsDataType.denominations:
        apiPath = apiDenominations();
        break;
      case SelectOptionsDataType.organizationsExpertiseAreas:
        apiPath = apiOrganizationsExpertiseAreas();
        break;
      case SelectOptionsDataType.organizationsTypes:
        apiPath = apiOrganizationsTypes();
        break;
      case SelectOptionsDataType.contacts:
        apiPath = apiContacts(searchString);
        break;
      default:
        throw new Error('Invalid data type specified');
    }

    const response: SelectOptionsResponse = await fetcher(apiPath, setIsLoading);

    if (dataType === SelectOptionsDataType.organizations || dataType === SelectOptionsDataType.languages) {
      const cachedOptions = localStorage.getItem(LocalStorageKeys[dataType]);
      const newUniqueCachedOptions: Item[] = cachedOptions ? JSON.parse(cachedOptions) : [];

      response.data.forEach((item) => {
        if (!newUniqueCachedOptions.some((option) => option.value === item.value)) newUniqueCachedOptions.push(item);
      });

      localStorage.setItem(LocalStorageKeys[LocalStorageKeys[dataType]], JSON.stringify(newUniqueCachedOptions));
    } else if (dataType === SelectOptionsDataType.contacts && userId) {
      return { ...response, data: response.data.filter((user) => user.value !== userId) };
    }

    return response;
  };

  const fetchProfileListsDataOptions = async (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
  ): Promise<ProfileListsDataSelectOptionsResponse> => fetcher(apiProfileListsData(), setIsLoading);

  const fetchLessons = async (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    materialId: string,
  ): Promise<LessonsResponse> => fetcher(apiLessons(materialId), setIsLoading);

  const fetchLessonSelectionModes = async (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    activityType: EventsActivityTypes,
  ): Promise<LessonModeOption[]> => fetcher(apiLessonSelectionModes(activityType), setIsLoading);

  return { fetchSelectOptions, fetchProfileListsDataOptions, fetchLessons, fetchLessonSelectionModes };
};
