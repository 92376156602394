import { Dispatch, SetStateAction } from 'react';
import { apiGenericBanner, apiMobileBanner } from '@/utils/apiUrls';
import { GenericBannerResponse, MobileBannerResponse } from '@/types/banners';
import { useCommonFetchers } from '@/hooks/useCommonFetchers';

export const useBannersFetchers = () => {
  const { fetcher } = useCommonFetchers();

  const fetchGenericBanner = async (
    setIsLoading: Dispatch<SetStateAction<boolean>>,
  ): Promise<GenericBannerResponse> => {
    return fetcher(apiGenericBanner(), setIsLoading);
  };

  const fetchMobileBanner = async (setIsLoading: Dispatch<SetStateAction<boolean>>): Promise<MobileBannerResponse> => {
    return fetcher(apiMobileBanner(), setIsLoading);
  };

  return { fetchGenericBanner, fetchMobileBanner };
};
