export enum EventsTimePeriods {
  ongoing = 'ongoing',
  future = 'future',
  past = 'past',
  pastAndToday = 'past_and_today',
  ongoingAndFuture = 'ongoing_and_future',
}

export enum EventsActivityTypes {
  healing = 'Healing',
  equipping = 'Equipping',
  convening = 'Convening',
  cop = 'COP',
}

export enum EventsEquippingLevels {
  initial = 'Initial',
  advanced = 'Advanced',
  supplemental = 'Supplemental',
}

export enum EventsMaterialsLessonsModes {
  core = 'Core',
  all = 'All',
  coreAndOptionalLessons = 'Core + Optional Lesson',
  optionalThiMini = 'Optional (TH mini)',
}

export enum FullEventsMaterialsLessonsModes {
  core = 'Core',
  all = 'All',
  coreAndOptionalLessons = 'Core + Optional Lesson',
  optionalLessons = 'Optional (TH mini)',
}

export enum FailedAddingParticipantsToEventReasons {
  notEnoughSessions = 'Not enough sessions',
  overqualified = 'Overqualified',
  underqualified = 'Underqualified',
  notExists = 'Not exists',
  noSessions = 'No sessions',
}
