import styled from "styled-components";
import { Header } from "antd/lib/layout/layout";

export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  height: fit-content;
  padding: 16px 24px;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 51, 51, 0.05);
  line-height: 1;

  @media screen and (max-width: 767px) {
    padding: 16px;
  }
`;

export const StyledParagraph = styled.p`
  color: #000;
  margin: 0;
  padding: 0;
  line-height: normal;
`;

export const StyledWelcome = styled(StyledParagraph)`
  font-size: 24px;
  font-weight: 600;
`;

export const StyledDate = styled(StyledParagraph)`
  font-size: 14px;
  font-weight: 400;
`;

export const StyledImage = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
