import { Flex, Grid } from "antd/lib";
import { ChevronRightStroke, MenuStroke, ThiLogo, ThiLogoMini } from "@/public/icons";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useMenu } from "@/context/MenuContext";
import { useSidebarTutorial } from "@/context/SidebarTutorialContext";

const { useBreakpoint } = Grid;

interface LogoWithBurgerProps {
  setLogoIsRendered?: Dispatch<SetStateAction<boolean>>;
}

const LogoWithBurger: React.FC<LogoWithBurgerProps> = ({ setLogoIsRendered }) => {
  const { collapsed, setCollapsed } = useMenu();
  const { logoRef } = useSidebarTutorial();
  const screens = useBreakpoint();

  useEffect(() => {
    if (!setLogoIsRendered) return;

    setLogoIsRendered(true);
  }, []);

  return (
    <Flex
      gap={16}
      style={{
        marginTop: screens.lg ? "16px" : 0,
        marginBottom: screens.lg ? "45px" : 0,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div onClick={() => setCollapsed((prev) => !prev)} style={{ cursor: "pointer" }}>
        {collapsed && screens.lg ? <ChevronRightStroke /> : <MenuStroke />}
      </div>

      <a href="https://traumahealinginstitute.org/" target="_blank" ref={logoRef}>
        {collapsed && screens.lg ? <ThiLogoMini /> : <ThiLogo />}
      </a>
    </Flex>
  );
};

export default LogoWithBurger;
