import { SearchParams } from '@/types/common';
import { ComponentType, lazy } from 'react';
import { EventsActivityTypes } from '@/constants/events';
import {
  myConveningReportingUrl,
  myCopReportingUrl,
  myEquippingReportingUrl,
  myHealingReportingUrl,
} from '@/utils/appUrls';
import { Event } from '@/types/events';

export const buildUrlWithParams = (basePath: string, path = '/', params?: SearchParams) => {
  const url = `${basePath}${path}`;
  if (!params) return url;

  const searchParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== '') {
      acc.append(key, value);
    }

    return acc;
  }, new URLSearchParams());

  return Array.from(searchParams).length ? `${url}?${searchParams}` : url;
};

export function debounce(callback: (...args: any) => void, delay: number) {
  let timer = 0;

  return (...args: any[]) => {
    clearTimeout(timer);

    timer = window.setTimeout(() => {
      callback(...args);
    }, delay);
  };
}

export const prepareEmails = (string: string) => {
  const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@]+/g;
  const asciiRegex = /^[\x00-\x7F]+$/;

  const emails = string.match(emailRegex);

  return emails ? Array.from(new Set(emails)).filter(email => asciiRegex.test(email)) : [];
};

export const lazyRetry = <T extends ComponentType<any>>(
  componentImport: Parameters<typeof lazy<T>>[0],
): Promise<{ default: T }> => {
  const sessionStorageKey = 'page-has-been-force-refreshed';

  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed due to this chunk
    const hasRefreshed =
      typeof window !== 'undefined' ? JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || 'false') : 'false';

    // try to import the component
    componentImport()
      .then((component) => {
        // success so reset the refresh state
        if (typeof window !== 'undefined') window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (typeof window === 'undefined') return;

        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          // refresh the page
          return window.location.reload();
        }

        // Default error behaviour as already tried refresh
        reject(error);
      });
  });
};

export const getReportingUrl = (eventType: Event['event_type']) => {
  switch (eventType) {
    case EventsActivityTypes.healing:
      return myHealingReportingUrl(0);
    case EventsActivityTypes.equipping:
      return myEquippingReportingUrl(0);
    case EventsActivityTypes.cop:
      return myCopReportingUrl(0);
    case EventsActivityTypes.convening:
      return myConveningReportingUrl(0);
    default:
      throw new Error(`No activity type ${eventType}`);
  }
};
