import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Flex, Skeleton } from "antd/lib";
import styles from "@/src/components/Common/Banners/Banners.module.scss";
import { GenericBannerResponse, MobileBannerResponse } from "@/types/banners";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import { useBannersFetchers } from "@/hooks/useBannersFetchers";
import GenericBanner from "@/src/components/Common/Banners/GenericBanner";
import MobileBanner from "@/src/components/Common/Banners/MobileBanner";

interface BannersProps {
  bannersAreLoaded: boolean;
  setBannersAreLoaded: Dispatch<SetStateAction<boolean>>;
  setShouldShowTourBannersStep?: Dispatch<SetStateAction<boolean>>;
}

const Banners: React.FC<BannersProps> = ({ bannersAreLoaded, setBannersAreLoaded, setShouldShowTourBannersStep }) => {
  const { fetchGenericBanner, fetchMobileBanner } = useBannersFetchers();
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState<{ generic: GenericBannerResponse; mobile: MobileBannerResponse }>({
    generic: null,
    mobile: null,
  });

  useEffect(() => {
    if (isLoading) {
      if (bannersAreLoaded) setBannersAreLoaded(false);

      return;
    }

    setBannersAreLoaded(true);
  }, [isLoading]);

  useEffect(() => {
    const fetchBanners = async () => {
      const [generic, mobile] = await Promise.all([fetchGenericBanner(setIsLoading), fetchMobileBanner(setIsLoading)]);

      setBanners({ generic, mobile });
      if ((generic || mobile) && setShouldShowTourBannersStep) setShouldShowTourBannersStep(true);

      setIsLoading(false);
    };

    fetchBanners();
  }, []);

  return (
    <div className={styles.banners}>
      <Skeleton active loading={isLoading}>
        <QueueAnim delay={300} duration={1000}>
          <Flex vertical gap={24}>
            {!!banners.generic && (
              <GenericBanner
                imageUrl={banners.generic["hero-image"].en[0]}
                text={banners.generic["hero-primary-text"].en}
                buttonText={banners.generic["button-text"].en}
                buttonUrl={banners.generic["button-url"].en}
              />
            )}
            {!!banners.mobile && (
              <MobileBanner
                imageUrl={banners.mobile["hero-image"].en[0]}
                mainText={banners.mobile["hero-primary-text"].en}
                secondaryText={banners.mobile["hero-secondary-text"].en}
                appStoreUrl={banners.mobile["appstore-link"].en}
                googlePlayUrl={banners.mobile["google-play-link"].en}
              />
            )}
          </Flex>
        </QueueAnim>
      </Skeleton>
    </div>
  );
};

export default Banners;
