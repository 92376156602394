import { buildUrlWithParams } from '@/utils/helpers';
import { SearchParams as SearchParamsType } from '@/types/common';
import { SearchParams } from '@/constants/common';

const SQUIDEX_ASSETS_BASE_PATH = 'https://cloud.squidex.io/api/assets/thi-beacon/';
export const squidexAssetUrl = (nestedRoute = '') => buildUrlWithParams(SQUIDEX_ASSETS_BASE_PATH, nestedRoute);

// Dashboard

export const DASHBOARD_BASE_PATH = '/dashboard';
export const dashboardUrl = (nestedRoute = '', params?: SearchParamsType) =>
  buildUrlWithParams(DASHBOARD_BASE_PATH, nestedRoute, params);

// Reporting
export const MY_REPORTING_BASE_PATH = `${DASHBOARD_BASE_PATH}/my-reporting`;
export const myReportingUrl = (nestedRoute = '', params?: SearchParamsType) =>
  buildUrlWithParams(MY_REPORTING_BASE_PATH, nestedRoute, params);
export const myHealingReportingUrl = (areReported: 0 | 1) =>
  myReportingUrl('/healing', { [SearchParams.filterReported]: areReported.toString() });
export const myEquippingReportingUrl = (areReported: 0 | 1) =>
  myReportingUrl('/equipping', { [SearchParams.filterReported]: areReported.toString() });
export const myCopReportingUrl = (areReported: 0 | 1) =>
  myReportingUrl('/cop', { [SearchParams.filterReported]: areReported.toString() });
export const myConveningReportingUrl = (areReported: 0 | 1) =>
  myReportingUrl('/convening', { [SearchParams.filterReported]: areReported.toString() });
export const reportMyEventUrl = (id?: string, activityTypeId?: string) =>
  myReportingUrl('/create', { id, [SearchParams.activityTypeId]: activityTypeId });
export const healingBulkUploadUrl = (params?: SearchParamsType) => myReportingUrl('/healing-bulk-upload', params);
export const createOrganizationUrl = (params?: SearchParamsType) => myReportingUrl('/create-organization', params);

// Find a user dashboard for admins
export const findUsersDashboardUrl = (params?: SearchParamsType) => dashboardUrl('/find-users', params);

// Impact
export const myImpactUrl = (params?: SearchParamsType) => dashboardUrl('/my-impact', params);

// Profile
export const MY_PROFILE_BASE_PATH = `${DASHBOARD_BASE_PATH}/my-profile`;
const myProfileUrl = (nestedRoute: string, params?: SearchParamsType) =>
  buildUrlWithParams(MY_PROFILE_BASE_PATH, nestedRoute, params);

export const personalInfoUrl = (params?: SearchParamsType) => myProfileUrl('/personal-info', params);
export const myCertificationsUrl = (params?: SearchParamsType) => myProfileUrl('/certifications', params);

// Programs
export const MY_PROGRAMS_BASE_PATH = `${DASHBOARD_BASE_PATH}/my-programs`;
const myProgramsUrl = (nestedRoute: string, params?: SearchParamsType) =>
  buildUrlWithParams(MY_PROGRAMS_BASE_PATH, nestedRoute, params);

export const favoritesProgramsUrl = (params?: SearchParamsType) => myProgramsUrl('/favorites', params);
export const programsForMyEventsUrl = (params?: SearchParamsType) => myProgramsUrl('/programs-for-my-events', params);

export const myEventMaterialsUrl = (eventId: string) => myProgramsUrl('/programs-for-my-events', { eventId });

// My events
export const MY_EVENTS_BASE_PATH = `${DASHBOARD_BASE_PATH}/my-events`;
export const myEventsUrl = (nestedRoute?: string, params?: SearchParamsType) =>
  buildUrlWithParams(MY_EVENTS_BASE_PATH, nestedRoute || '', params);

export const ongoingMyEventsUrl = (params?: SearchParamsType) => myEventsUrl('/ongoing', params);
export const futureMyEventsUrl = (params?: SearchParamsType) => myEventsUrl('/future', params);
export const pastMyEventsUrl = (params?: SearchParamsType) => myEventsUrl('/past', params);
export const myEventDetailsUrl = (id: string) => myEventsUrl('', { id });
export const createEventUrl = () => myEventsUrl('/create');
export const editEventUrl = (id: string) => myEventsUrl('/edit', { id });

// Materials

export const materialsLibraryUrl = (params?: SearchParamsType) => buildUrlWithParams('/materials-library', '', params);

// Public events

export const PUBLIC_EVENTS_BASE_PATH = '/events';
export const publicEventsUrl = (nestedRoute?: string, params?: SearchParamsType) =>
  buildUrlWithParams(PUBLIC_EVENTS_BASE_PATH, nestedRoute || '', params);

export const publicEventDetailsUrl = (id: string) => publicEventsUrl('', { id });

// Training

export const TRAINING_BASE_PATH = '/training';
const trainingUrl = (nestedRoute?: string, params?: SearchParamsType) =>
  buildUrlWithParams(TRAINING_BASE_PATH, nestedRoute || '', params);

export const adultTrainingUrl = (params?: SearchParamsType) => trainingUrl('/adult', params);
export const childrenTrainingUrl = (params?: SearchParamsType) => trainingUrl('/children', params);
export const teensTrainingUrl = (params?: SearchParamsType) => trainingUrl('/teens', params);

// Cookies
export const cookieConsentUrl = (params?: SearchParamsType) => buildUrlWithParams('/cookie-consent', '', params);

// Feedback

export const participantFormUrl = (params?: SearchParamsType) => buildUrlWithParams('/participant-form', '', params);
