import React from "react";
import Image from "next/image";
import styles from "@/src/components/Common/Banners/Banners.module.scss";
import { Flex, Grid } from "antd/lib";
import { squidexAssetUrl } from "@/utils/appUrls";
import appStoreImage from "@/public/img/appStore.svg";
import googlePlayImage from "@/public/img/googlePlay.svg";

interface MobileBannerProps {
  imageUrl: string;
  mainText: string;
  secondaryText: string;
  appStoreUrl: string;
  googlePlayUrl: string;
}

const { useBreakpoint } = Grid;

const MobileBanner: React.FC<MobileBannerProps> = ({
  imageUrl,
  mainText,
  secondaryText,
  appStoreUrl,
  googlePlayUrl,
}) => {
  const screens = useBreakpoint();

  return (
    <Flex className={`${styles.banner} ${styles.banner_mobile}`}>
      <div className={styles.content}>
        <p className={`${styles.text} ${styles.text_main}`}>{mainText}</p>
        <p className={`${styles.text} ${styles.text_secondary}`}>{secondaryText}</p>
      </div>

      <Flex gap={16} align="center" vertical={!screens.xl}>
        <Flex vertical gap={9} align="center" className={styles.linkButtonsWrapper}>
          <a href={appStoreUrl} className={styles.linkBtnForAppStoreAndPlayMarket}>
            <Image src={appStoreImage} alt="App Store" width={122} height={39} />
          </a>
          <a href={googlePlayUrl} className={styles.linkBtnForAppStoreAndPlayMarket}>
            <Image src={googlePlayImage} alt="Google Play" width={122} height={39} />
          </a>
        </Flex>
        <Image src={squidexAssetUrl(imageUrl)} alt="" width={88} height={88} />
      </Flex>
    </Flex>
  );
};

export default MobileBanner;
