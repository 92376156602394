"use client";

import "@/public/fonts/fontIcons.css";
import "./globals.css";
import { Montserrat } from "next/font/google";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "@/context/AuthContext";
import { SidebarTutorialProvider } from "@/context/SidebarTutorialContext";
import { ConfigProvider, Flex, Grid, Layout } from "antd/lib";
import theme from "@/src/theme/themeConfig";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { dashboardUrl, materialsLibraryUrl, MY_REPORTING_BASE_PATH } from "@/utils/appUrls";
import Script from "next/script";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Envs, LocalStorageKeys, SearchParams } from "@/constants/common";
import Bugsnag from "@/bugsnag";
import { MyReportingProvider, MyReportingProviderProps } from "@/context/MyReportingContext";
import "../i18n";
import { MenuProvider } from "@/context/MenuContext";
import { usePath } from "@/hooks/usePath";
import { App } from "antd";
import Sidebar from "@/src/components/Sidebar/Sidebar";
import AppHeader from "@/src/components/AppHeader";
import PageTitle from "@/src/components/Common/PageTitle";
import Banners from "@/src/components/Common/Banners/Banners";
import AppFooter from "@/src/components/AppFooter";
import dynamic from "next/dynamic";
import { lazyRetry } from "@/utils/helpers";
import GoogleAnalytics from "@/src/components/Common/GoogleAnalytics";
import { useSearchParams } from "next/navigation";

const CookieConsentModal = dynamic(() => lazyRetry(() => import("@/src/components/modalDialogs/CookieConsentModal")), {
  ssr: false,
});

const montserrat = Montserrat({ subsets: ["latin"] });

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const contentStyle: React.CSSProperties = {
  padding: 0,
  minHeight: "100vh",
};

const footerStyle: React.CSSProperties = {
  padding: 0,
  margin: 0,
  bottom: 0,
};

const { useBreakpoint } = Grid;

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePath();
  const screens = useBreakpoint();

  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);
  const [bannersAreLoaded, setBannersAreLoaded] = useState(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem(LocalStorageKeys.lastVisit);
    const currentDate = new Date();
    const lastVisitDate = new Date(lastVisit || "");

    const daysSinceLastVisit = Math.round((currentDate.getTime() - lastVisitDate.getTime()) / (1000 * 60 * 60 * 24));

    localStorage.setItem(LocalStorageKeys.lastVisit, new Date().toISOString());

    if (!lastVisit || daysSinceLastVisit >= 30) {
      setIsCookiesModalOpen(true);
    }
  }, []);

  const handleCookiesModalClose = () => {
    setIsCookiesModalOpen(false);
  };

  return (
    <html lang="en">
      <GoogleAnalytics />
      <body className={montserrat.className}>
        <ErrorBoundary>
          <Auth0Provider
            domain={process.env.AUTH0_DOMAIN as string}
            clientId={process.env.AUTH0_CLIENT_ID as string}
            authorizationParams={{
              audience: process.env.AUTH0_AUDIENCE as string,
              redirect_uri: typeof window !== "undefined" ? window.location.origin : "",
            }}
            cacheLocation="localstorage"
            onRedirectCallback={(appState: AppState | undefined) => {
              if (!appState?.returnTo) return;

              window.location.href = appState.returnTo;
            }}
          >
            <App>
              <ConfigProvider theme={theme}>
                <AuthProvider>
                  <MenuProvider>
                    <SidebarTutorialProvider
                      cookiesModalIsOpen={isCookiesModalOpen}
                      bannersAreLoaded={bannersAreLoaded}
                    >
                      <MyReportingPagesProvider bannersAreLoaded={bannersAreLoaded}>
                        <Layout hasSider className="container">
                          <Sidebar />
                          <Layout>
                            <Flex vertical gap={32}>
                              <Header style={{ padding: 0, height: "fit-content" }}>
                                <AppHeader loading={false} />
                              </Header>

                              <Flex vertical gap={56}>
                                <Content
                                  style={{
                                    overflow: "initial",
                                    padding: screens.md ? "0 24px" : "0 16px",
                                    minHeight: "100vh",
                                  }}
                                >
                                  {!screens.lg && <PageTitle />}

                                  <BannersWrapper
                                    bannersAreLoaded={bannersAreLoaded}
                                    setBannersAreLoaded={setBannersAreLoaded}
                                  />

                                  {children}

                                  <CookieConsentModal
                                    isModalOpen={isCookiesModalOpen}
                                    onClose={handleCookiesModalClose}
                                  />
                                </Content>
                                <Footer style={footerStyle}>
                                  <AppFooter />
                                </Footer>
                              </Flex>
                            </Flex>
                          </Layout>
                        </Layout>
                      </MyReportingPagesProvider>
                    </SidebarTutorialProvider>
                  </MenuProvider>
                </AuthProvider>
              </ConfigProvider>
            </App>
          </Auth0Provider>
          {(process.env.APP_ENV === Envs.development || process.env.APP_ENV === Envs.staging) && (
            <Script
              id="marker-io"
              dangerouslySetInnerHTML={{
                __html: `
              window.markerConfig = {
                project: '64ff22850ee7f9636492a93d',
                source: 'snippet'
              };

              !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
            `,
              }}
            />
          )}
        </ErrorBoundary>
      </body>
    </html>
  );
}

const MyReportingPagesProvider: React.FC<MyReportingProviderProps> = ({ children, bannersAreLoaded }) => {
  const pathname = usePath();

  return pathname.startsWith(MY_REPORTING_BASE_PATH) ? (
    <MyReportingProvider bannersAreLoaded={bannersAreLoaded}>{children}</MyReportingProvider>
  ) : (
    <>{children}</>
  );
};

interface BannersWrapperProps {
  bannersAreLoaded: boolean;
  setBannersAreLoaded: Dispatch<SetStateAction<boolean>>;
}

const BannersWrapper: React.FC<BannersWrapperProps> = ({ bannersAreLoaded, setBannersAreLoaded }) => {
  const pathname = usePath();
  const searchParams = useSearchParams();
  const materialFileCertificationAreaId = searchParams.get(SearchParams.filterMaterialFileCertificationAreaId);

  if (pathname === dashboardUrl() || (pathname === materialsLibraryUrl() && !!materialFileCertificationAreaId))
    return <></>;

  return <Banners bannersAreLoaded={bannersAreLoaded} setBannersAreLoaded={setBannersAreLoaded} />;
};
