import { AnalyticsBrowser } from '@segment/analytics-next';

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.SEGMENT_KEY as string,
});

export enum Envs {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

export enum SearchParams {
  id = 'id',
  filterOnline = 'filter[online]',
  filterLanguage = 'filter[language]',
  filterSearch = 'filter[search]',
  filterLocation = 'filter[location]',
  filterTimePeriod = 'filter[time_period]',
  filterReported = 'filter[reported]',
  filterActivityType = 'filter[activity-type]',
  filterEquippingLevel = 'filter[equipping_level]',
  filterMaterials = 'filter[material]',
  filterMaterialAge = 'filter[material-age]',
  filterMaterialFileTagAge = 'filter[file_tag_id_age]',
  filterMaterialFileTagAccess = 'filter[file_tag_id_access]',
  filterMaterialFileTagEquippingType = 'filter[file_tag_id_equipping_type]',
  filterMaterialFileContent = 'filter[file_content_id]',
  filterStartDate = 'filter[start_date]',
  filterEndDate = 'filter[end_date]',
  filterMaterialFileContentIsFavorite = 'filter[file_content_is_favorite]',
  filterMaterialFileContentName = 'filter[file_content_name]',
  filterMaterialFileContentLanguage = 'filter[file_content_language_id]',
  filterMaterialFileTagCategory = 'filter[file_tag_id_category]',
  filterMaterialFolderHasParents = 'filter[folder_has_parents]',
  filterMaterialFolderIsFavorite = 'filter[folder_is_favorite]',
  filterMaterialFolderId = 'filter[folder_id]',
  filterMaterialFileCertificationAreaId = 'filter[file_certification_area_id]',
  filterIsFavorite = 'filter[is_favorite]',
  include = 'include',
  offset = 'offset',
  limit = 'limit',
  activityTypeId = 'activity_type_id',
  referralToken = 'referral_token',
  sessionId = 'session_id',
  sessionName = 'session_name',
  sessionState = 'session_state',
  demo = 'demo',
}

export enum FilterTypes {
  common = 'common',
  materials = 'materials',
}

export enum LocalStorageKeys {
  tourWasShown = 'tourWasShown',
  lastVisit = 'lastVisit',
  organizations = 'organizations',
  languages = 'languages',
  descriptionsEnabled = 'descriptionsEnabled',
  languagesEnabled = 'languagesEnabled',
  materialsCountEnabled = 'materialsCountEnabled',
  necessaryCookies = 'necessaryCookies',
  performanceCookies = 'performanceCookies',
}

export enum sessionStates {
  active = 'active',
  completed = 'completed',
}

export enum SelectOptionsDataType {
  languages = 'languages',
  organizations = 'organizations',
  internationalOrganizations = 'internationalOrganizations',
  materials = 'materials',
  myMaterials = 'myMaterials',
  audiences = 'audiences',
  ages = 'ages',
  activityTypes = 'activityTypes',
  people = 'people',
  countries = 'countries',
  denominations = 'denominations',
  activityLanguages = 'activityLanguages',
  contacts = 'contacts',
  organizationsExpertiseAreas = 'organizationsExpertiseAreas',
  organizationsTypes = 'organizationsTypes',
  lessons = 'lessons',
}

export enum CachedSelectOptionsDataType {
  languages = 'languages',
  organizations = 'organizations',
}

export const urlPattern = /^(?!.*@)(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;

export class ErrorWithMessageAndResponseStatus extends Error {
  public responseStatus: number;

  constructor(message: string, responseStatus: number) {
    super();
    this.message = message;
    this.responseStatus = responseStatus;
  }
}

export const AbortedErrorName = 'AbortError';
export const TypeError = 'TypeError';

export const xlsMimeType = 'application/vnd.ms-excel';
export const xlsxMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
